import React from "react";
import ButtonInputSearch from "../../../components/ButtonInputSearch";
import ButtonIconLabel from "../ButtonIconLabel";

import favorite from "./svgs/favorite.svg";
import media from "./svgs/media.svg";
import content from "./svgs/content.svg";
import creative from "./svgs/creative.svg";
import design from "./svgs/design.svg";
import personalized from "./svgs/personalized.svg";

import * as S from "./styles";

const Filters = ({ selectView }) => {
  return (
    <S.WrapperFilters>
      <div style={{ margin: "0 6px" }}>
        <ButtonInputSearch />
      </div>

      <div style={{ margin: "0 6px" }}>
        <ButtonIconLabel
          icon={favorite}
          label="Favoritos"
          bgColor="#E7E7E7"
          handleInput={() => console.log("click Favoritos")}
        />
      </div>
      <div style={{ margin: "0 6px" }}>
        <ButtonIconLabel
          icon={media}
          label="Mídia"
          bgColor="#1883FF"
          handleInput={() => console.log("click Mídia")}
        />
      </div>
      <div style={{ margin: "0 6px" }}>
        <ButtonIconLabel
          icon={content}
          label="Conteúdo"
          bgColor="#E7E7E7"
          handleInput={() => console.log("click Conteúdo")}
        />
      </div>
      <div style={{ margin: "0 6px" }}>
        <ButtonIconLabel
          icon={creative}
          label="Criação"
          bgColor="#E7E7E7"
          handleInput={() => console.log("click Criação")}
        />
      </div>
      <div style={{ margin: "0 6px" }}>
        <ButtonIconLabel
          icon={design}
          label="Design"
          bgColor="#E7E7E7"
          handleInput={() => console.log("click Design")}
        />
      </div>
      <S.Divisor />

      <ButtonIconLabel
        icon={personalized}
        label="Personalizado"
        bgColor="#E7E7E7"
        handleInput={() => selectView("CART")}
      />
    </S.WrapperFilters>
  );
};

export default Filters;
