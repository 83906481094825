import React, { useState } from "react";

import iconSearch from "./svg/ico-search.svg";
import * as S from "./styles";
import { I18n } from "aws-amplify";

const ButtonInputSearch = (props) => {
  const [openInput, setOpenInput] = useState(false);

  const handleInput = () => {
    openInput ? setOpenInput(false) : setOpenInput(true);
  };

  return (
    <S.Wrapper>
      {openInput ? (
        <S.WrapperInput>
          <input
            placeholder={I18n.get("Buscar")}
            onChange={(e) => props.getValue(e.target.value)}
          />
        </S.WrapperInput>
      ) : null}
      <S.Button onClick={handleInput}>
        <S.Icon>
          <img alt="Icon Search" src={iconSearch} />
        </S.Icon>
        <S.Label>{I18n.get("Buscar")}</S.Label>
      </S.Button>
    </S.Wrapper>
  );
};

export default ButtonInputSearch;
