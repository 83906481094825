import React from 'react';

const TermsPartnerEN = () => {

    return (
        <div class="termsContainer">
            <div class="termsContent">

                <p>This Term of Use (&ldquo;Term&rdquo;) governs the use of the online platform (&ldquo;Platform&rdquo;) created and developed by <strong>BOUTIQUE POOL INTERMEDIA&Ccedil;&Atilde;O E SERVI&Ccedil;OS DE MARKETING LTDA</strong>., a company headquartered at Av. Nove de Julho 5229, 1st floor in the city and State of S&atilde;o Paulo, CEP 01.406-200, registered with CNPJ/MF under number 31.391.925/0001-34, (&ldquo;Boutique Pool&rdquo;) by marketing service agencies, creation, planning, content and/or technology production companies and studios in multiple media and production hubs that provide advertising and marketing services.</p>
                <p><strong>COMPLIANCE WITH THIS TERM IS ABSOLUTELY ESSENTIAL FOR THE USE OF THE BOUTIQUE POOL PLATFORM AND ITS SERVICES.</strong></p>
                <p>Partner should read, make sure it has understood, and agree with all the conditions established in this Term, before contracting projects on the platform.</p>
                <ol>
                    <li>Definitions For all purposes and effects of this Term, the following terms and expressions beginning in capital letters, whether in the singular or in the plural form, will have the meanings indicated below, with due regard to other terms defined under this instrument:</li>
                </ol>
                <p>&ldquo;Partner&rdquo; or &ldquo;Creative Partner&rdquo; means both marketing agencies and creation, planning, content production, design, and/or technologies in multiple media companies and studios, both small and medium-sized, and production hubs that provide marketing services available for contracting by Customers through the Platform;</p>
                <p>&ldquo;Matchmaking&rdquo; means the steps for selecting Creative Partners promoted by the Platform, based on a proprietary algorithm developed by the platform and specific criteria and determined according to the Project requested by Customer. At the end of the Matchmaking stage, the Platform will indicate three ideal Creative Partners to the demand requested by Customer;</p>
                <p>&ldquo;Curation&rdquo; means the application of analysis methodologies and selection of Platform participants on a recurring basis, using human intervention from Boutique Pool, curators, and evaluation algorithms for the approval of Partners who are within the standards required by Boutique Pool, to then be indicated and connected with Customers, respecting the particularities and expectations necessary for the good development of business between both parties;</p>
                <p>"Asset" creative product priced and with a detailed scope, such as landing page, website, concept board, creative concept, packaging design, point of sale material design, among others</p>
                <p>"Asset Market"a list of creative products in a market place format, categorized by type and communication and marketing services specialty presented to Customer, duly priced with full scope details. Customer may select projects in the Asset Market (assets packages) or may assemble its own project by assembling its individual asset.</p>
                <p>&ldquo;Service&rdquo; or &ldquo;Project&rdquo; means the marketing services contracted by Customer from the selected Creative Partners or projects developed for Customers by Partners, in a specific way, by using tools from the Platform. The Services may include communication services in general, events, training and/or research, as well as creating printed, electronic, audiovisual, or radio material, subject to the legal protections inherent to Copyright defined throughout this Term.</p>
                <p>&ldquo;Competition&rdquo; means stages of a specific selection other than Matchmaking, through which Creative Partners may be invited by Customers to present commercial and creative proposals for a given Project. In such cases, Creative Partner and Customer must adhere to a specific term, which will contain the rules of the respective Competition.</p>
                <ol start="2">
                    <li>Registration. The Platform is only available to legal entities or individuals who are legally able to contract under Brazilian law. Persons without such ability, minors, or Partners whose registrations are suspended or canceled may not use the Platform.</li>
                </ol>
                <p>2.1. To be registered on the Platform, a Partner needs to fill in all fields of the registration form available on the Platform with valid information, in an exact, accurate, and true manner (&ldquo;Registration&rdquo;), providing documents and information proving its identity and ability to contract on behalf of the respective legal entity, as requested for the Registration. Boutique Pool may request other documents and additional information. Partner is committed to keep Registration information up to date.</p>
                <p>2.2. In addition to the information mentioned in item 2.1. above, Partner must provide a creative portfolio, a history of cases and clients, and also submit to three references for validation as requested in the Registration. Boutique Pool reserves the right not to accept the registration of a Partner at its sole discretion.</p>
                <p>2.3. The documentation provided by Partner in the Registration must be current, legal, and in force. Boutique Pool does not assume any responsibility for active supervision of Partners, so that losses arising from false or inaccurate information will be the sole responsibility of Partners.</p>
                <p>2.4. Upon Registration, Partner must inform an email and create a password that obeys the parameters defined by Boutique Pool to access the Platform. The password will be personal and non-transferable, and it is hereby established that Boutique Pool will not request such password by any other means, or for any other reason, other than to access to the Platform.</p>
                <p>2.5. Partner will be solely responsible for all the operations carried out in its Registration, and must keep its password confidential. Boutique Pool is not responsible for operations carried out in its Registration. If it founds unauthorized use or access of its registration, Partner must report such fact to Boutique Pool immediately for the purpose of suspending the registration.</p>
                <p>2.6. Partner may request the cancellation of its registration on the Platform at any time, without prejudice to requests for Services already contracted with Customers at the time of the cancellation request, which must be completed normally, as if the registration for such purposes remained active, in the terms of the Project Specific Term defined below.</p>
                <ol start="3">
                    <li>Use of the Platform : Upon completion of its Registration and adherence to this Term, Partner will become part of the Platform and will be able to be appointed to provide the Services, using the tools available on the Platform, which include (i) briefing formalization, final scope and approval of each Project; (ii) exchange completion messages for each stage of the Projects, their completion of these or schedule changes; (iii) partial and final evaluations by Customers to Boutique Pool; (iv) formalization of the final files exchanged between the parties about a Project; and (v) formalization of signatures of the Specific Project Term; and (vi) invoicing from one party to the other.</li>
                </ol>
                <p>3.1. When using the Platform, Partners will receive the right to onerous and non-exclusive use of the software that makes up the Platform, for use limited to this Term.</p>
                <p>3.2. Boutique Pool, as a business platform, will use its best efforts to curate the best Partners with ethics and creative quality for the Services demanded by the Client and thus promote the environment so that the Service is provided in the best way possible, being responsible for auditing the relationships established between Customers and Partners through the Platform, exchanging and excluding Creative Partners who do not provide the Services in accordance with the Platform rules and/or when requested by Customer.</p>
                <p>3.3. The application of Curation, Matchmaking and / or Competitions, and contracting must be carried out in an ethical and responsible manner by the parties involved, with the definition of the contracting premises in advance of contracting the Services. Partners must respect Boutique Pool's and Customers' Ethics and Anti-Corruption Codes.</p>
                <ol start="4">
                    <li>Platform Flow. Boutique Pool will provide the Client and Creative Partners with the use of the Platform according to the following flow:</li>
                </ol>

                <img src="https://assets-ollo.bpool.co/termos/TermsFlowEN.png" />

                <ol start="5">
                    <li>Competitions. In some cases, it will be up to Boutique Pool, through the Platform's intelligence, to organize the Competitions between Creative Partners, which will be carried out through the adhesion by the parties of terms specific to each Competition. Competitions will be held in the Platform environment and/or in person. Boutique Pool will use its best efforts to carry out the Competitions fairly, and it is up to Customers and Creative Partners to adapt to the rules determined on the Platform</li>
                </ol>
                <ol start="6">
                    <li>Service or Project Selection. Once selected to develop a Project or provide a Service, according to the Curation and Matchmaking procedures carried out, Creative Partner will receive an email inviting it to participate in such Project with all the details of the Service and/or Project, including delivery time and amounts, among other information (&ldquo;Invitation&rdquo;)..</li>
                </ol>
                <p>6.1. Partner must indicate whether or not to attend to the Project contained in the Invitation received within 1 (one) business day (understood until the same time as the next business day) as of the receipt of the Invitation, carried out in the Platform environment. A Partner's silence will be interpreted by Boutique Pool as refusal to accept a Project. If the term established in this item falls on a weekend, the first subsequent business day will be considered.</p>
                <p>6.2. The three selected Partners who accepted the Invitation will be presented to Customer and the Platform will assist Customer in choosing by submitting the portfolio, history, and credentials of such Partners, as well as stimulating face-to-face conversations or making calls so that Customer can choose at its exclusive criterion.&nbsp;</p>
                <p>6.3. Customer will have to decide among these Partners which one is the most suitable for the Service or Project within the term selected on the Platform. If Customer does not select a Partner within the respective term, Creative Partner is authorized to withdraw from that specific Matchmaking and/or Competition.</p>
                <p>6.4. The decision to hire a particular Partner is the sole and exclusive responsibility of Customer and Boutique Pool does not have any responsibility for such choice.</p>
                <ol start="7">
                    <li>Project Specification Term (&ldquo;TEP&rdquo;). Once the Matchmaking has been carried out and a Partner has been selected by Customer, the final scope and macro-stages of the Project are adjusted on the platform, and upon final acceptance, the specific TEP of that contract is generated, which must be signed in digital form between Customer and Partner, with the intervention of Boutique Pool, establishing the specific rights and obligations of Customer and Partner with respect to the Services and/or the selected Project, detailing the Project to be developed or the Service to be provided to Customer, including the terms and form of payment of Partner's remuneration and regulating the relationship between these parties.</li>
                </ol>
                <p>7.1. Any Creative Partner selected by Customer for the provision of Services or the execution of Projects under this Term will be linked to the TEP. Customer's obligations with Creative Partner are directly enforceable by Creative Partner to Customer.</p>
                <ol start="8">
                    <li>Evaluation. The Platform will contain tools for evaluating the Service or Project carried out by Partner according to each of the steps defined at the beginning of each Project/Service contracted between Customers and Partners.. In the event of assessments inconsistent with a Customer's expectations and briefing, Partner must adapt the materials submitted to assessment, redoing them according to the instructions indicated in writing by Customer. While such adjustment is made, specific payments to this Partner may be suspended, subject to the amounts related to the completed services. Once the conclusion of any adjustments or a certain stage of contracting occurs and upon the satisfactory evaluation of Customer who contracted the Services, the specific payment to Partner will be made.</li>
                </ol>
                <p>8.1. The Platform will limit the number of times that a given Project can be redone or adapted, avoiding to overburden Partners. In cases where a given Customer exceeds the quantities of adjustments allowed or changes the briefing and scope of Services in a Project too many times, it will be at the discretion of each Partner to negotiate with the customer a new contracting on the Platform, or through the functionality called &ldquo;extension of Projects&rdquo; available on the Platform.</p>
                <p>8.2. Evaluations carried out on the Platform may contain public information, as long as they have been previously approved by Partners and Customers, when they will then be available to all those registered in the Platform. Assessments should be carried out in good faith and in an objective manner, indicating feedback to Partners in a concise manner. The Platform will not make partial evaluations of Creative Partners publicly available while certain Projects are being carried out.</p>
                <p>8.3. If a Partner disagrees with an evaluation carried out, it may request its review, by Boutique Pool, as well as clarification of any questions.</p>
                <p>8.4. Boutique Pool may exclude from the Platform Partners who receive 3 or more bad evaluations, in specific contracts, over a period of up to 6 months. In these cases, Boutique Pool will inform the excluded Partner, in writing, of the reasons for its exclusion. Boutique Pool will consider evaluations as bad when the overall score given at the end of the Project is below 3, regular between 3.0 and 3.99, good between 4.0 and 4.74 and excellent from 4.75 to 5.</p>
                <ol start="9">
                    <li>Platform Development and Maintenance. Boutique Pool is responsible for developing and keeping the Platform updated so that it organizes the relationships between Customers and Partners, in a safe manner and respecting the premises defined in each Project. Boutique Pool may interrupt the availability of the Platform at any time at scheduled stops, without the need for prior notice to Customers and Partners, making efforts, however, so that such stops to be made outside business hours. Boutique Pool is not required to keep the Platform available indefinitely, and there is no liability whatsoever to Customers and Partners in the event of an eventual unscheduled outage.</li>
                </ol>
                <p>9.1. Boutique Pool will not be responsible for any virus that could infect Partner/Customer's equipment as a result of accessing, using, or checking the Platform or any transfer of data, files, images, text, or audio contained therein. Partner/Customer may not assign any liability to Boutique Pool, nor demand payment for loss of profits, due to losses resulting from non-compliance with the provisions contained herein, resulting from technical difficulties or failures in the systems or on the Internet. Boutique Pool does not guarantee continued or uninterrupted access and use of the Platform. The system may not be available due to technical difficulties or Internet failures, or for any other circumstance beyond the control of Boutique Pool and in such cases Boutique Pool will try to restore accessibility to the Platform as soon as possible, without this being able to imputing some kind of responsibility. Boutique Pool will not be responsible for any errors or omissions contained on its Website or Platform.</p>
                <p>9.2. Partners are absolutely prohibited from any action or use of devices, software, or other means with the purpose of interfering in the activities and operations of the Platform. Any interference, activity, or attempted violation or that is contrary to the laws, including, without limitation, those dealing with intellectual property rights, and/or the prohibitions stipulated in this Term will be subject to the relevant legal actions.</p>
                <ol start="10">
                    <li>No Competition. When requested by a Customer, the Platform will not list as an option Partners who are working with other clients in the same category as Customer. Therefore, when registering on the Platform, each Creative Partner must indicate the brands of products and/or services with which they may already work (whose criterion is the invoicing of an Invoice in the last 3 months) to establish non-competition practices and thus, a Creative Partner does not develop any type of work for competing products and/or services.</li>
                </ol>
                <p>10.1. For the purpose of establishing non-competition practices, only the brands of products and/or services within the given category will be considered, with which a given Creative Partner works, not companies that own and market the brands of such products and/or services. Thus, Creative Partners will not be able to act only for brands of competing products and/or services directly in the same category, not for companies that are their manufacturers.</p>
                <p>10.2. If a Creative Partner already has non-competition agreements with one of its customers when registering and using the Platform, it must provide such information when starting its relationship with Boutique Pool, during registration, indicating the option of &ldquo;active non-compete''.</p>
                <p>10.3. Creative Partners must keep updated the list of companies, products, and services with which they maintain or have maintained a commercial relationship in the last 3 (three) months, so that there are no conflicts between these and any Customers that may be referred to Creative Partners, in the terms of this item.</p>
                <p>10.4. If a Creative Partner's non-compete period is required for it to develop services for a particular Customer, it will be deemed that such Creative Partner has verified and agreed to such a limitation by accepting to start work with such Customer, and there is no liability whatsoever for Boutique Pool in the event that there are demands regarding the non-compete period already accepted in a given Project.</p>
                <ol start="11">
                    <li>Remuneration and Payment Method. Customer must remunerate Creative Partners under the terms defined for each Service or Project contracted, according to each Invitation and TEP entered into between Customer and Partner, including when there are staggered payments (&ldquo;Remuneration&rdquo;). Boutique Pool will manage the payment flow of the contracted Projects, releasing the payments according to the conclusion of pre-defined stages between the Parties, retaining only the amounts that are due through the intermediation of the contracted Services.</li>
                </ol>
                <p>11.1. In case of attempts to change the scope by Customer after the approval of a Project, it will be up to Creative Partner to inform Boutique Pool about such changes to avoid possible losses. Extension of standard scopes of the Platform may also be contracted through the &ldquo;Extension of Non-Standardized Projects and Items&rdquo; functionality on the Platform, through the &ldquo;Production&rdquo; Menu within each Project.</p>
                <p>11.2. Payments made by Customer will be forwarded to Boutique Pool, which will manage all these payments, passing them on to each Creative Partner within the conditions of each Project. In certain Projects, Boutique Pool will be able to manage the payment in stages to a Creative Partner, as each of these stages is completed. Payments to Creative Partners must, in any case, observe the payment terms of Customers, and it should also be considered by Partner that the transfer of such payment by Boutique Pool will be made in 3 (three) business days after the actual receipt of the amounts paid by Customer.</p>
                <p>11.3. The Parties agree that the payment of the Remuneration by Customer will occur according to the flow described below.</p>
                <p>(i) Customer must enter order or purchase order (&ldquo;Order&rdquo;) number when applicable or, authorize Invoicing on the Platform;</p>
                <p>(ii) After placing the Order on the Platform or invoicing authorization, Creative Partner will be notified by email with instructions and must: (1) issue an invoice against Customer in the amount of Remuneration provided for Partner, according to the final approved scope and/or TEP in the care of Boutique Pool, following invoicing instructions in the notification and specifying in the body of the said invoice that the amounts will be on behalf of Boutique Pool services intermediary, against the intermediation note; (2) upload the invoice issued on the Platform; (3) immediately communicate Boutique Pool through the email suporte.parceiro@bpool.co in the event of any technical problem that would impair or prevent the issuance of the invoice;</p>
                <p>(iii) Boutique Pool, in turn, will issue an invoice adding the Services provided by Creative Partner in the &ldquo;Service Intermediation&rdquo; field and the Transactional Fee, which will be described transparently in the Invitation and in the TEP (&ldquo;Transactional Fee&rdquo;), and will send to the client according to instructions inserted in the platform (email, system upload, and/or physical receipt);</p>
                <p>(iv) Customer fully pay the invoice referred to in item (iii) in Boutique Pool's current account and the latter will pass on to the partner the value of its services, as established in the TEP.</p>
                <p>Sole Paragraph: The Parties agree that, especially in relation to the obligations contained in paragraph (ii) above, Creative Partner will be required to issue the respective Invoice and upload it on the link provided by Boutique Pool within 48 (forty-eight) hours from receipt of the e-mail containing instructions in this regard. Subject to the provision in item (3) of item (ii), it is hereby agreed that Creative Partner's failure to meet the aforementioned period will prompt the application of a penalty of 2% (two percent) on the price of the services, a percentage which will be increased by 0.5% (zero point five per cent) per day of delay, as arrears, limited to 5% (five per cent).</p>
                <p>11.4. In case of delay in the payment of the Remuneration through the invoices sent, received, and not challenged by Customer, Customer will be subject to a fine of 2% (two percent), in addition to default interest of 1% (one percent) per month, calculated pro rata die. The fine and default interest referred to in this item will be due to Creative Partner and Boutique Pool, proportionally to their respective remunerations, according to the contracting terms of each Project or Service.</p>
                <p>11.5. Partner is aware and declares to agree that after the invitation is accepted and the TEP signed by the Parties, the responsibility for the payment of the Service rests with Customer. If Customer does not pay the Remuneration, it will be subject to the penalties provided for in the Term of Use signed by Customer and in the TEP. Boutique Pool does not make any advance payments to Partner for any reason.</p>
                <ol start="12">
                    <li>Copyright. For the purposes of this Term, &ldquo;Copyright&rdquo; means the moral and patrimonial rights of the creations prepared during the provision of marketing services in each Project, as long as they contain original and intellectual creations capable of highlighting an idea or concept for a product or service. In this sense, Copyright may include creations in printed, electronic, audiovisual, or radio material. Creative Partners must retain ownership of the Copyright of materials which they may submit for use on the Platform.</li>
                </ol>
                <p>12.1. Partners must create original concepts that will be offered for contracting by Customers. In case of verification of the use of non-original materials, Partners may be excluded from the Platform, without prejudice to the right to indemnity that a specific Customer that may be adversely affected may seek against Partner. Boutique Pool is not responsible for the violation of third-party Copyrights in the provision of the Services, and the injured party must seek its rights from Partner who violated such rights.</p>
                <p>12.2. All brands, trade names, domain names, and other distinctive signs of Boutique Pool on the Platform, as well as the programs, databases, networks, and files, which allow Partner to access and use the Platform, are the property of Boutique Pool and are protected by international copyright laws and treaties, trademarks, patents, models, and industrial designs. The misuse and the total or partial reproduction of the referred contents are prohibited, except with the express authorization of Boutique Pool.</p>
                <p>12.3. Any interference, attempt, or activity that violates or contravenes the laws of intellectual property rights and/or the prohibitions stipulated in this Term, will make the person liable to the pertinent legal actions, as well as the sanctions provided for herein, being also responsible for indemnities due to possible sanctions.</p>
                <ol start="13">
                    <li>Assignment of Copyright. Materials subject to Copyright will be assigned to Customers when a certain Partner is selected in a specific Curation, Matchmaking, or Competition and the Service provided. Such assignment is costly under the terms of each contracting, specific for use in each Project contracted and limited to the uses provided for in each Project. In the absence of a specific definition, the assignment will be considered onerous and unlimited, definitive and will cover all materials produced by Creative Partner that are subject to Copyright.</li>
                </ol>
                <p>13.1. Copyright must be assigned by Partner with the guarantee that it obtained the rights of the authors of the materials contained in the marketing services to be provided to Customers, the individuals who prepared these materials. In case of absence in obtaining the assignment from individuals, it will be up to each Customer to decide between obtaining the assignment of such Copyright in an additional way or canceling the hiring of a certain Partner. Boutique Pool will be responsible for the exclusion of this Partner from the Platform, at the request of Customer, if Partner does not provide Customer with documents capable of demonstrating that the Copyright has been properly assigned to it. Moral rights over marketing materials are not assigned, pursuant to Article 24 of the Copyright Law (Law 9810/98), and it is the responsibility of Customers to name their creators in the the marketing materials exhibition that have been created.</p>
                <ol start="14">
                    <li>Use of Third-Party Image. Partner is the sole and exclusive responsible for obtaining all authorizations to use the image of third parties in any and all material necessary to provide the Services. Such authorization must cover the use of the third party's image throughout the national territory and abroad, without entitlement to claims related to the holder's image.</li>
                </ol>
                <p>14.1. If it is necessary to pay any amount for the use of third-party images, this amount must be included in the Remuneration to be paid by Customer, with no additional amount due for this purpose.</p>
                <ol start="15">
                    <li>Authorizations. Partner declares that it owns and will obtain from third parties, when applicable, all licenses, permissions, authorizations, and other rights necessary for the provision of the Services.</li>
                </ol>
                <ol start="16">
                    <li>Boutique Pool Performance. Boutique Pool acts in the connection between Customer and Partner and its responsibility is limited to the services provided by it through the Platform, as described in this Term.</li>
                </ol>
                <p>16.1. In the event of a conflict between Customer and Partner, Boutique Pool will use its best efforts to resolve such conflict in accordance with the rules pre-defined in the Platform and TEP definitions, but cannot, however, be held responsible for the payment of damages, including, but not limited to, property damage, pain and suffering, and lost profits.</p>
                <p>16.2. Customer may take the necessary legal measures directly with Partner, if it wants to obtain compensation for any property damage and pain and suffering caused to itself or to third parties.</p>
                <p>16.3. Notwithstanding the provisions above, in any event, Boutique Pool's liability under this instrument is limited to the Remuneration amount for each Service/Project contracted on the Platform.</p>
                <ol start="17">
                    <li>Exclusion from the Platform. Boutique Pool may exclude from the Platform Partners who do not act in accordance with these Terms, ethics, or Brazilian law. In the event that Boutique Pool becomes aware of negotiation or attempted negotiation between Customers and Partners outside the Platform environment, after it begins in the Platform environment, exclusion will be summarily carried out.</li>
                </ol>
                <p>17.1. In the event of exclusion during a Curation, Matchmaking, or Competition procedure, Boutique Pool may suspend these procedures without the effective hiring of a Partner (in the event of exclusion of a Customer), or suspend the participation of a Partner in a specific Curation, Matchmaking or Competition procedure, without returning any amounts incurred by the Party that may be excluded from the Platform.</p>
                <p>17.2. For the purposes of this clause, in the cases in which Boutique Pool has not been remunerated, it will also be due to its commissioning foreseen for each Project, by Customer in case the Project has not yet been paid or by Partner, if it has already received the amounts for this Project, without prejudice to other losses and damages that may be required.</p>
                <p>17.3. The only exception to the above rule will be in the event that a given Creative Partner receives an offer from a Project by Boutique Pool and it is found that it has already maintained a commercial relationship with a Customer selected by Boutique Pool in the previous 12 months. In this case, Boutique Pool must be informed immediately so that it can change the conditions of the direct relationship between Customer and Creative Partner, noting that in this case the remuneration of the Platform will only be considered for Projects that start from using the Platform. If there is a Customer definition for all Projects with this Creative Partner to be concentrated on the Platform, from then on, Boutique Pool must establish specific values that contemplate such scenario.</p>
                <ol start="18">
                    <li>Contractual breach. Without prejudice to other measures, Boutique Pool may warn, suspend or cancel, temporarily or permanently, a Partner's account on the Platform, at any time, and initiate appropriate legal actions: a) if Partner (i) fails to comply with any provision of this Term, (ii) fails to fulfill its Partner duties with Boutique Pool and Customer, or (iii) commits fraudulent or intentional acts; b) if any information provided by Partner is incorrect; or c) if Boutique Pool understands that any action taken by Partner has caused damage to third parties or Boutique Pool or has the potential to do so.</li>
                </ol>
                <p>18.1. Partner will indemnify Boutique Pool, its subsidiaries, controlled or controlling companies, directors, administrators, employees, representatives and employees for any demand promoted by other Partners, Customers, or third parties, arising from their activities on the Platform or for their non-compliance with this Term, for violation of any law or the rights of third parties, notably those linked to the confidentiality of information received from Customer, the privacy of data accessed under this instrument, as well as the regulation of advertising activities.</p>
                <ol start="19">
                    <li>Conflict Resolution. Boutique Pool may mediate any conflicts between Customers and Partners so that they are resolved in accordance with the Terms set forth herein and the applicable Brazilian law, adopting methods that are transparent to Customers and Partners and may retain the amount involved until the conflict is between Customers and Partners.</li>
                </ol>
                <p>19.1. In all mediations, all parties involved will endeavor to ensure that the procedures are carried out and completed within a maximum of 30 days from the beginning.</p>
                <p>19.2. If any conflicts are not resolved by the mediation provided for in this item within a maximum period of 60 days from the beginning, such conflict(s) must be resolved through arbitration, under the terms of the ARBITRANET Regulation, by an arbitration tribunal composed of 3 (three) arbitrators, who will be appointed in accordance with the provisions of said regulation. The arbitration will be conducted in Portuguese and should take place in the city of S&atilde;o Paulo, State of S&atilde;o Paulo, Brazil.</p>
                <p>19.3. Without prejudice to the provisions of Clause 19.2. above, the Parties recognize that any of them may appeal to the Judiciary Branch exclusively for the following measures, and such measures should not be interpreted as a waiver by the Parties of the arbitration procedure: (i) to obtain preliminary and protective measures prior to confirmation by the arbitration tribunal; (ii) to execute any decision of the arbitration tribunal, including the final sentence; (iii) to execute this Instrument specifically; and (iv) for other procedures expressly permitted by Law No. 9,307/96, as amended. For the purposes of the provisions in this item, the forum of the capital of S&atilde;o Paulo is hereby elected.</p>
                <ol start="20">
                    <li>Working Relationships. Under no circumstances, the existence or the presumption of any employment relationship or labor and social security obligations will be established between the Parties, by themselves and/with the representatives or employees of the other Party, nor any of the Parties will guarantee the labor and social obligations and charges of the other Party, which herein assumes full responsibility for such obligations, including civil and criminal. The Parties will be fully responsible for the services of third parties that may eventually be used to fulfill the obligations assumed in the Platform. In this sense, they will be responsible for the obligations imposed by labor and social security legislation, being responsible for paying all tax, labor, social, and social security charges of their employees allocated in the performance of their activities.</li>
                </ol>
                <p>20.1. Neither Boutique Pool nor Customer will have any responsibility over third parties hired by Partner for the provision of the Services, who is the sole and exclusive responsible for the terms of such contracting, and must immediately exclude Boutique Pool and/or Customer from any demand to which they must respond with respect to these Partners.</p>
                <ol start="21">
                    <li>Taxes. Taxes of any nature that are due as a direct result of Projects provided through contracting through the Platform are the sole responsibility of the taxpayer of the respective tax, as defined in the tax rule.</li>
                </ol>
                <ol start="22">
                    <li>Agreement. This Term constitutes the entire agreement between the Parties, regarding the use of the Platform and prevails over any previous agreements.</li>
                </ol>
                <ol start="23">
                    <li>Amendments. This Term will be periodically reviewed by Boutique Pool, which may change it, excluding, modifying, or inserting clauses or conditions, at its sole discretion. However, Partner will be notified whenever there is a change in the provisions of this Term so that it can be aware of the new conditions. If it does not agree with the changes, it must cancel its account and stop using the Platform.</li>
                </ol>
                <ol start="24">
                    <li>Anti-Corruption. The Parties shall comply with and ensure that all their employees, subcontractors, consultants, agents, or representatives comply with Law 12.846/13, as well as Customers' code of ethics, if any. Neither Party shall pay, offer, promise, or give, directly or indirectly, any amount or thing of value, including any amounts paid to it as a result of the contracted services, to any employee or official of a government, company or corporation controlled or owned by the government, political party, candidate for political office, or any other person, aware of or believing that such value or item of value will be passed on to someone to influence any action or decision by that person or by any government agency with purpose of obtaining, retaining, or conducting business for any of the Parties.</li>
                </ol>
                <ol start="25">
                    <li>Partner hereby represents and warrants to Boutique Pool that it has read, understood and agrees with all the provisions of this Term.</li>
                </ol>
                <ol start="26">
                    <li>Partner, as well as its respective legal representatives, declare that they are duly authorized to accept this Term, in the form of their respective corporate instruments.</li>
                </ol>
                <ol start="27">
                    <li>This agreement is written in English and Spanish. The version in English will prevail over the Spanish version, which is for mere reference.</li>
                </ol>

            </div>
        </div>
    );
}

export default TermsPartnerEN;
