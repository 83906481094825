import React from "react";
import * as S from "./styles";
import { I18n } from "aws-amplify";
import Close from "../../../svgs/close.svg";
import Clip from "../../../svgs/clip.svg";

const ListFiles = ({ files, deleteFile, statusProject, auth }) => {
  return (
    <S.List>
      <S.Icon>
        <img src={Clip} alt="" />
      </S.Icon>
      {files?.length > 0 ? (
        files.map((item) => {
          return (
            <S.FileItem key={item?.id}>
              <S.FileName>
                <a href={item?.url}>{item?.name}</a>
              </S.FileName>
              {statusProject !== 7 ? (
                <S.Close onClick={() => deleteFile(item?.id)}>
                  <img src={Close} alt="" />
                </S.Close>
              ) : auth?.username === item?.uploadedByUsername ? (
                <S.Close onClick={() => deleteFile(item?.id)}>
                  <img src={Close} alt="" />
                </S.Close>
              ) : null}
            </S.FileItem>
          );
        })
      ) : (
        <div>{I18n.get("Nenhum arquivo encontrado")}</div>
      )}
    </S.List>
  );
};

export default ListFiles;
